import React, { useState } from 'react';
import { Row, Form, Col, Button, Card } from 'react-bootstrap';
import { SOFT_SKILLS } from './soft-skills.constant';
function SoftSkills() {
    const [skills, setSkills] = useState(SOFT_SKILLS);
    const [selectedSkill, setSelectedSkill] = useState('interruption');
    const [selectedScore, setSelectedScore] = useState(0);

    const updateScore = () => {
        localStorage.setItem(selectedSkill, JSON.stringify({ score: selectedScore }));
    }

    const getScore = () => {
        let previousScore = localStorage.getItem(selectedSkill);
        if (previousScore) {
            alert("El ultimo puntaje fue " + JSON.parse(previousScore).score)
        } else {
            alert("No hay puntajes asociados ");
        }
    }

    return (
        <div>
            <h4>Feedback</h4>
            <p><i><small>Al identificar algo que debemos mejorar, debemos solicitar retroalimentación de personas confiables mientras lo mejoramos.</small></i></p>
        
            <Row>
                <Col xs="12">
                <Card><Card.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="habilidad">
                                <Form.Control as="select"
                                    value={selectedSkill}
                                    onChange={
                                        e => { setSelectedSkill(e.target.value) }
                                    }
                                >
                                    {skills.map((skill, keySkill) => {
                                        return <option key={skill.key} value={skill.key}>{skill.value}</option>
                                    })}

                                </Form.Control>
                            </Form.Group>
                        </Col></Row>
                    <Row><Col>
                        <div >
                            <Form.Group key="inline-radio" className="mb-3"
                                onChange={e => {
                                    setSelectedScore(e.target.value)
                                }}>
                                <Form.Check inline label="1" value="1" type="radio" name="inline-radio" id="one-rate" />
                                <Form.Check inline label="2" value="2" type="radio" name="inline-radio" id="two-rate"
                                />
                                <Form.Check inline label="3" value="3" type="radio" name="inline-radio" id="three-rate"
                                />
                                <Form.Check inline label="4" value="4" type="radio" name="inline-radio" id="four-rate"
                                />
                                <Form.Check inline label="5" value="5" type="radio" name="inline-radio" id="five-rate" />
                            </Form.Group>
                        </div>
                    </Col></Row>
                    <Row className="mt-4"><Col>
                        <Button variant="dark"
                            onClick={e => { updateScore() }}
                        >Actualizar</Button>{' '}
                        <Button variant="light"
                            onClick={e => { getScore() }}
                        >Ver ultimo puntaje</Button>
                        {' '}
                        {/*<Button variant="light"
                onClick={
                    e=>{
                        setSelectedScore(0)
                        setSelectedSkill('interruption')
                    }
                }
                >Limpiar</Button>
                */}
                    </Col></Row>
                </Card.Body></Card>
            </Col>
          
            </Row>

        </div>
    )
}
export default SoftSkills;
