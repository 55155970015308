import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
function Consecuence(props) {
    const handleEvent = (event) => {
        props.removeConsecuenceChild(event);
    };

    return (
        <Card className={(props.consecuencia.tipo === 'Positiva' ? 'green' : 'red')}>
            <Card.Body>
                <p align="center">
                    <i>
                        {props.consecuencia.descripcion}</i><b> ({props.consecuencia.peso})</b>
                </p>
                <Row>
                    <Col align="center">
                        <FontAwesomeIcon size="2x" icon={faTrash}
                            onClick={e => { handleEvent(props.consecuencia) }}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}
export default Consecuence;
