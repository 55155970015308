export let SOFT_SKILLS = [
    {
        key: 'interruption',
        value: 'Manejo de interrupciones'
    },
    {
        key: 'receiving_criticism',
        value: 'Recibir criticas'
    },
    {
        key: 'listening',
        value: 'Escuchar'
    },    
    {
        key: 'empathy',
        value: 'Ponerse en los zapatos del otro'
    },
    {
        key: 'communicating',
        value: 'Capacidad y claridad al comunicar'
    },
    {
        key: 'agilism',
        value: 'Capacidad de adaptarse al cambio'
    },
]

    /*
    {
        key: 'presenting',
        value: 'Capacidad para presentar'
    },
    {
        key: 'defining_clear_goals',
        value: 'Definir metas claras'
    },
    {
        key: 'teaching',
        value: 'Ensenar'
    },
    {
        key: 'listening',
        value: 'Escuchar'
    },
    {
        key: 'follow_ups',
        value: 'Seguimiento'
    },
    */