import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Col, Row, Form, Card} from 'react-bootstrap';
import NumberFormat from 'react-number-format'

function BasicDecisions() {
  const [probabilityRight, setProbabilityRight] = useState(50);
  const [reward, setReward] = useState(0);
  const [rightValue, setRightValue] = useState(0);

  const [probabilityWrong, setProbabilityWrong] = useState(50);
  const [penalty, setPenalty] = useState(0);
  const [wrongValue, setWrongValue] = useState(0);

  const calculateRightValue = (currentProbability, currentReward) => {
    
    setProbabilityRight(currentProbability)
    
    let rightValue = 0

    if (currentProbability > 0 && currentReward > 0) {
      rightValue = (currentProbability / 100) * currentReward
    }
    setRightValue(rightValue)
  }

  const assignWrongProbability = (right) => {
    setProbabilityWrong(100)
    if (right > 0) {
      setProbabilityWrong(100 - right)
    }
  }

  const calculateWrongValue = (currentProbabilityWrong,currentPenalty) => {
    let wrongValue = 0

    if (currentProbabilityWrong > 0 && currentPenalty > 0) {
      wrongValue = (currentProbabilityWrong / 100) * currentPenalty
    }
    setWrongValue(wrongValue)
  }

  const rightCall = {
    color: '#00ae4d'
  };

  const wrongCall = {
    color: '#cb5400'
  };
  return (
      <div>
        <h4>Vale la pena</h4>
            <p><i><small>Si la recompensa es elevada, valdrá la pena aunque nos equivoquemos (y podamos asumir equivocarnos).</small></i></p>
           <Row>
          <Col xs="12" md="6">
            
        <Card>
          <Card.Body>
            <Row>
              <Col >
                <h6>Desición Correcta</h6>
                <Form>
                  <Form.Group controlId="formWrongProbability">
                    <Form.Label>Probabilidad</Form.Label>
                    <Form.Control type="number" placeholder="Ingresa la probabilidad de que esta desición salga bien"
                      value={probabilityRight}
                      onChange={e => {
                        setProbabilityRight(e.target.value)
                        assignWrongProbability(e.target.value)
                        calculateRightValue(e.target.value, reward)
                        calculateWrongValue(100-e.target.value, penalty)
                      }}
                    />
                  </Form.Group>
    
                  <Form.Group controlId="formReward">
                    <Form.Label>Recompensa</Form.Label>
                    <Form.Control type="number" placeholder="Ingresa la recompensa esperada"
                      value={reward}
                      onChange={e => {
                        setReward(e.target.value)
                        calculateRightValue(probabilityRight, e.target.value)
                      }}></Form.Control>
                  </Form.Group>
                </Form>
              </Col>
    
    
            </Row>
            <Row>
              <Col>
                <b>
                  <NumberFormat
                    value={rightValue}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  />
                </b>
              </Col>
            </Row>
    
            </Card.Body>
            </Card>
            </Col>
            <Col>
            
            <Card>
              <Card.Body>  
                
            <Row>
              <Col>
                <h6>Desición Incorrecta</h6>
                <Form>
                  <Form.Group controlId="formWrongProbability">
                    <Form.Label>Probabilidad</Form.Label>
                    <Form.Control type="number" disabled placeholder="Ingresa la probabilidad de que esta desición salga mal"
                      value={probabilityWrong}
                      onChange={e => setProbabilityWrong(e.target.value, penalty)}
                    />
                  </Form.Group>
    
                  <Form.Group controlId="formPenalty">
                    <Form.Label>Pérdida</Form.Label>
                    <Form.Control type="number" placeholder="Ingresa lo que perderías"
                      value={penalty}
                      onChange={e => {
                        setPenalty(e.target.value)
                        calculateWrongValue(probabilityWrong,e.target.value)
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
    
    
            </Row>
            <Row>
              <Col>
                <b><NumberFormat
                    value={wrongValue}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                  /></b>
              </Col>
            </Row>
              </Card.Body>
            </Card>
            </Col>
            
            </Row>
            <Row  className="mt-5">
              <Col>
                {
                  rightValue >= wrongValue ? (
                    <h3 align="center" style={rightCall}> + 
                    
                    <NumberFormat
                    value={ rightValue - wrongValue}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'} 
                  /> Debes hacerlo</h3>
                  ) : <h3 align="center" style={wrongCall}> - <NumberFormat
                  value={ wrongValue- rightValue }
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                /> No debes hacerlo</h3>
                }
              </Col>
            </Row>  
            </div>
      );
}
export default BasicDecisions;
