import React, { useContext, useReducer } from "react";
import {
  Container,
  Button,
  Col,
  Row,
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import "./App.css";

import BasicDecisions from "./components/basicDecisions/basicDecisions";
import InvestingPrinciple from "./components/investingPrinciple/InvestingPrinciple";
import SoftSkills from "./components/skills/soft-skills";
import Consecuences from "./components/consecuences/consecuences";
import Listening from "./relationship/components/Listening";
import SectionReducer from "./reducers/SectionReducer";
import { SectionContext } from "./context/SectionContext";

function App({ store }) {
  const FEEDBACK_SOFT_SKILLS = 0;
  const BASIC_DECISIONS = 1;
  const INVESTING_DECISIONS = 2;
  const REAL_CONSECUENCES = 3;
  const LISTENING = 4;
  const [state, dispatch] = useReducer(SectionReducer, {
    section: REAL_CONSECUENCES,
  });

  function dispatchChangeState(section) {
    dispatch({
      section: section,
    });
  }
  // const [selectedSection, setSelectedSection] = useState(REAL_CONSECUENCES)
  return (
    <div className="App">
      <Navbar bg="dark" variant="dark" expand="md">
        <Navbar.Brand className="title">Principios</Navbar.Brand>
        <Nav className="mr-auto">
          <NavDropdown title="Vitales / Humanos" id="collasible-nav-dropdown">
            <NavDropdown.Item
              href="#home"
              onClick={(e) => {
                dispatchChangeState(REAL_CONSECUENCES);
              }}
            >
              Perspectiva
            </NavDropdown.Item>

            <NavDropdown.Item
              href="#feedback"
              onClick={(e) => {
                dispatchChangeState(FEEDBACK_SOFT_SKILLS);
              }}
            >
              Feedback
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown title="Relaciones / Pareja" id="collasible-nav-dropdown">
            <NavDropdown.Item
              href="#listening"
              onClick={(e) => dispatchChangeState(LISTENING)}
            >
              Listening
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title="Trabajo"
            id="collasible-nav-dropdown"
          ></NavDropdown>
          <NavDropdown title="Inversión" id="collasible-nav-dropdown">
            <NavDropdown.Item
              href="#worthit"
              onClick={(e) => {
                dispatchChangeState(BASIC_DECISIONS);
              }}
            >
              Vale la pena
            </NavDropdown.Item>
            <NavDropdown.Item
              href="#investment"
              onClick={(e) => {
                dispatchChangeState(INVESTING_DECISIONS);
              }}
            >
              Cuando invertir
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar>
      <Container className="mt-2">
        {/*
        <Breadcrumb className="white-background">
          <Breadcrumb.Item href="#">{selectedSection}</Breadcrumb.Item>
        </Breadcrumb>*/}
        <Row>
          <Col>
            <SectionContext.Provider value={4}>
              {(() => {
                switch (state.section) {
                  case FEEDBACK_SOFT_SKILLS:
                    return <SoftSkills store={store} />;
                  case BASIC_DECISIONS:
                    return <BasicDecisions store={store} />;
                  case INVESTING_DECISIONS:
                    return <InvestingPrinciple store={store} />;
                  case REAL_CONSECUENCES:
                    return <Consecuences store={store} />;
                  case LISTENING:
                    return <Listening store={store} section={state.section} />;
                  default:
                    return "";
                }
              })()}
            </SectionContext.Provider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
