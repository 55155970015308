function SectionReducer(state, action) {
    switch (action.section) {
      // Soft skills
      case 0: {
        return {
          ...state,
          section: action.section,
        };
      }
      // Basic desitions
      case 1: {
        return {
          ...state,
          section: action.section,
        };
      }
      // Investing desitions
      case 2: {
        return {
          ...state,
          section: action.section,
        };
      }
      // Real consecuences
      case 3: {
        return {
          ...state,
          section: action.section,
        };
      }
      // Listening
      case 4: {
        return {
          ...state,
          section: action.section,
        };
      }
      default: {
        return {
            ...state,
            section: action.section,
          };
      }
    }
  }

  export default SectionReducer;