import React, { useEffect, useState } from 'react';
import { Container, Button, Col, Row, Form, Card, Nav, Accordion } from 'react-bootstrap';
import Consecuence from './consecuence';

const POSITIVA = "Positiva";
const NEGATIVA = "Negativa";
const INMEDIATA = "Inmediata";
const CORTO_PLAZO = "Corto plazo";
const LARGO_PLAZO = "Largo Plazo";
function Consecuences() {
    const [nuevaConsecuencia, setNuevaConsecuencia] = useState({
        tiempo: INMEDIATA,
        descripcion: "",
        tipo: POSITIVA,
        peso: 1,
    });

    const [consecuenciasInmediatas, setConsecuenciasInmediatas] = useState([]);

    const [descripcion, setDescripcion] = useState('');

    const [totalInmediatas, setTotalInmediatas] = useState(0);

    const [totalCortoPlazo, setTotalCortoPlazo] = useState(0);

    const [totalLargoPlazo, setTotalLargoPlazo] = useState(0);

    const [consecuenciasCortoPlazo, setConsecuenciasCortoPlazo] = useState([]);

    const [consecuenciasLargoPlazo, setConsecuenciasLargoPlazo] = useState([]);

    const setAttribConsecuencia = (key, value) => {
        let nuevoObjeto = { ...nuevaConsecuencia };
        nuevoObjeto[key] = value;
        setNuevaConsecuencia(nuevoObjeto);
    };

    useEffect(() => {
        updateTotal(INMEDIATA)
    }, [consecuenciasInmediatas]);

    useEffect(() => {
        updateTotal(CORTO_PLAZO)
    }, [consecuenciasCortoPlazo]);

    useEffect(() => {
        updateTotal(LARGO_PLAZO)
    }, [consecuenciasLargoPlazo]);

    const pushNuevaConsecuencia = (consecuencia) => {
        switch (consecuencia.tiempo) {
            case INMEDIATA:
                setConsecuenciasInmediatas([...consecuenciasInmediatas, consecuencia])
                break;
            case CORTO_PLAZO:
                setConsecuenciasCortoPlazo([...consecuenciasCortoPlazo, consecuencia])
                break;
            case LARGO_PLAZO:
                setConsecuenciasLargoPlazo([...consecuenciasLargoPlazo, consecuencia])
                break;
        }
    };

    const updateTotal = (tiempo) => {
        switch (tiempo) {
            case INMEDIATA:
                updateTotalInmediata()
                break
            case CORTO_PLAZO:
                updateTotalCorto()
                break
            case LARGO_PLAZO:
                updateTotalLargo()
                break
        }

    }

    const updateTotalInmediata = () => {
        setTotalInmediatas(0)
        consecuenciasInmediatas.forEach((data) => {
            if (data.tipo == POSITIVA) {
                setTotalInmediatas(parseInt(totalInmediatas, 10) + parseInt(data.peso, 10))
                // continue;
            }

            if (data.tipo == NEGATIVA) {
                setTotalInmediatas(parseInt(totalInmediatas, 10) - parseInt(data.peso, 10))
                // continue;
            }
        });
    }

    const updateTotalCorto = () => {
        setTotalCortoPlazo(0)
        consecuenciasCortoPlazo.forEach((data) => {
            if (data.tipo == POSITIVA) {
                setTotalCortoPlazo(parseInt(totalCortoPlazo, 10) + parseInt(data.peso, 10))
                // continue;
            }

            if (data.tipo == NEGATIVA) {
                setTotalCortoPlazo(parseInt(totalCortoPlazo, 10) - parseInt(data.peso, 10))
                // continue;
            }
        });
    }


    const updateTotalLargo = () => {
        setTotalLargoPlazo(0)
        consecuenciasLargoPlazo.forEach((data) => {
            if (data.tipo == POSITIVA) {
                setTotalLargoPlazo(parseInt(totalLargoPlazo, 10) + parseInt(data.peso, 10))
                // continue;
            }

            if (data.tipo == NEGATIVA) {
                setTotalLargoPlazo(parseInt(totalLargoPlazo, 10) - parseInt(data.peso, 10))
                // continue;
            }
        });
    }

    const quitarConsecuenciaInmediata = (consecuenciaABorrar) => {
        let consecuenciasState = [...consecuenciasInmediatas]
        consecuenciasState.forEach((consecuencia, indice)=>{
            if(consecuenciaABorrar.descripcion === consecuencia.descripcion){
                consecuenciasState.splice(indice,1);
            }
        })
        setConsecuenciasInmediatas(consecuenciasState)
    }

    
    const quitarConsecuenciaCortoPlazo = (consecuenciaABorrar) => {
        let consecuenciasState = [...consecuenciasCortoPlazo]
        consecuenciasState.forEach((consecuencia, indice)=>{
            if(consecuenciaABorrar.descripcion === consecuencia.descripcion){
                consecuenciasState.splice(indice,1);
            }
        })
        setConsecuenciasCortoPlazo(consecuenciasState)
    }

    const quitarConsecuenciaLargoPlazo = (consecuenciaABorrar) => {
        let consecuenciasState = [...consecuenciasLargoPlazo]
        consecuenciasState.forEach((consecuencia, indice)=>{
            if(consecuenciaABorrar.descripcion === consecuencia.descripcion){
                consecuenciasState.splice(indice,1);
            }
        })
        setConsecuenciasLargoPlazo(consecuenciasState)
    }
    return (
        <div>
            <h4>Perspectiva</h4>
            <p><i><small>No es aceptable que solo veamos las consecuencias inmediatas de una desición, debemos garantizar también ver las consecuencias a mediano y largo plazo antes de tomarla.</small></i></p>
            <Accordion className="mb-4">
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Desición
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group controlId="tiempo">
                                        <Form.Label>Descripcion</Form.Label>
                                        <Form.Control
                                            value={descripcion}
                                            onChange={
                                                e => { setDescripcion(e.target.value) }
                                            }
                                            autoComplete="off"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Consecuencias
                    </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group controlId="tiempo">
                                        <Form.Label>Consecuencia</Form.Label>
                                        <Form.Control
                                            value={nuevaConsecuencia.descripcion}
                                            onChange={
                                                e => { setAttribConsecuencia('descripcion', e.target.value) }
                                            }
                                            autoComplete="off"
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="tiempo">
                                        <Form.Label>Tiempo</Form.Label>
                                        <Form.Control as="select"
                                            value={nuevaConsecuencia.tiempo}
                                            onChange={
                                                e => { setAttribConsecuencia('tiempo', e.target.value) }
                                            }
                                        >
                                            <option value={INMEDIATA}>Inmediata</option>
                                            <option value={CORTO_PLAZO}>Corto plazo</option>
                                            <option value={LARGO_PLAZO}>Mediano / Largo Plazo</option>

                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="habilidad">
                                        <Form.Label>Tipo</Form.Label>
                                        <Form.Control as="select"
                                            value={nuevaConsecuencia.tipo}
                                            onChange={
                                                e => { setAttribConsecuencia('tipo', e.target.value) }
                                            }
                                        >
                                            <option value={POSITIVA}>Positiva</option>
                                            <option value={NEGATIVA}>Negativa</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group controlId="peso">
                                        <Form.Label>Peso</Form.Label>
                                        <Form.Control as="select"
                                            value={nuevaConsecuencia.peso}
                                            onChange={
                                                e => { setAttribConsecuencia('peso', e.target.value) }
                                            }
                                        >
                                            <option value="1">Relevante</option>
                                            <option value="2">Mediana</option>
                                            <option value="3">Muy significativa</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>


                            <Row className="mb-4">
                                <Col>
                                    <Button variant="dark"
                                        onClick={e => { pushNuevaConsecuencia(nuevaConsecuencia) }}
                                    >Anadir</Button>{' '}

                                </Col>
                            </Row>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <Row>
                <Col>
                    <h4>{descripcion}</h4>
                </Col>
            </Row>
            {consecuenciasInmediatas.length > 0 ?
                <Row>
                    <Col>
                        <h6>Consecuencias Inmediatas ({totalInmediatas})</h6>
                    </Col>
                </Row> : ''
            }
            <Row>
                {consecuenciasInmediatas.map((consecuencia, key) => {
                    return <Col key={key} >
                        <Consecuence removeConsecuenceChild={quitarConsecuenciaInmediata} consecuencia={consecuencia}></Consecuence>
                    </Col>
                })}
            </Row>

            {consecuenciasCortoPlazo.length > 0 ?
                <Row>
                    <Col>
                        <h6>Consecuencias de Corto Plazo ({totalCortoPlazo})</h6>
                    </Col>
                </Row> : ''
            }
            <Row>
                {consecuenciasCortoPlazo.map((consecuencia, key) => {
                    return <Col key={key} >
                        <Consecuence removeConsecuenceChild={quitarConsecuenciaCortoPlazo}  consecuencia={consecuencia}></Consecuence>
                    </Col>
                })}
            </Row>

            {consecuenciasLargoPlazo.length > 0 ?
                <Row>
                    <Col>
                        <h6>Consecuencias a Largo Plazo ({totalLargoPlazo})</h6>
                    </Col>
                </Row> : ''
            }
            <Row>
                {consecuenciasLargoPlazo.map((consecuencia, key) => {
                    return <Col key={key} >
                        <Consecuence removeConsecuenceChild={quitarConsecuenciaLargoPlazo}  consecuencia={consecuencia}></Consecuence>
                    </Col>
                })}
            </Row>
        </div>
    )
}
export default Consecuences;
