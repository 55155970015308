import React, { useState } from 'react';
import { Row, Form, Col, Card } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
function InvestingPrinciple() {
    const MIN_INVESTMENT_UNCERTAIN_FREE_RATIO = 3.5
    const MIN_INVESTMENT_FREE_RATIO = 4.5
    const MIN_INVESTMENT_CERTAIN_FREE_RATIO = 5.0
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [payday, setPayDay] = useState(0);
    const [maxDebt, setMaxDebt] = useState(0);
    const [maxInvestment, setMaxInvestment] = useState(0);
    const [minPayday, setMinPayday] = useState(0);
    const [uncertainty, setUncertainty] = useState(MIN_INVESTMENT_UNCERTAIN_FREE_RATIO);
    const MAX_DEBT_RATIO = 4

    const MAX_INVESTMENT_RATIO = 15

    const assignMaxDebt = (currentPayDay) => {
        setMaxDebt(0)
        if (totalExpenses > 0) {
            setMaxDebt(currentPayDay * MAX_DEBT_RATIO)
        }
    }

    const assignMinPayday = (totalExpenses) => {
        setMinPayday(0)
        if (totalExpenses > 0) {
            setMinPayday(totalExpenses / uncertainty)
        }
    }

    const assignMaxInvestment = (totalExpenses) => {
        setMaxInvestment(0)
        if (totalExpenses > 0) {
            setMaxInvestment(totalExpenses * MAX_INVESTMENT_RATIO)
        }
    }
    return (
        <div>
            <h4>Cuando Invertir</h4>
            <p><i><small>Antes de invertir en un proyecto / emprendimiento, debemos estar en condiciones de hacerlo.</small></i></p>

            <Row>
                <Col xs="12" md="6">
                    <Card>
                        <Card.Body>


                            <Row><Col>
                                <Form.Group controlId="gradoIncertidumbre">
                                    <Form.Label>Incertidumbre</Form.Label>
                                    <Form.Control as="select" defaultValue={MIN_INVESTMENT_UNCERTAIN_FREE_RATIO}
                                        onChange={e => {
                                            setUncertainty(e.target.value)
                                        }}
                                    >
                                        <option value={MIN_INVESTMENT_CERTAIN_FREE_RATIO}>Baja</option>
                                        <option value={MIN_INVESTMENT_FREE_RATIO}>Media</option>
                                        <option value={MIN_INVESTMENT_UNCERTAIN_FREE_RATIO}>Alta</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col></Row>
                            <Row>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="gastosTotales">
                                            <Form.Label>Gastos totales</Form.Label>
                                            <Form.Control type="number" placeholder="Ingresa los gastos totales"
                                                value={totalExpenses}
                                                onChange={e => {
                                                    setTotalExpenses(e.target.value)
                                                    assignMinPayday(e.target.value)
                                                    assignMaxInvestment(e.target.value)
                                                }}
                                            />
                                        </Form.Group>


                                    </Form>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form>
                                        <Form.Group controlId="libreMensual">
                                            <Form.Label>Libre</Form.Label>
                                            <Form.Control type="number" placeholder="Ingresa el dinero libre mensual"
                                                value={payday}
                                                onChange={e => {
                                                    setPayDay(e.target.value)
                                                    assignMaxDebt(e.target.value)
                                                }}
                                            />
                                        </Form.Group>


                                    </Form>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="12" md="6">
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    Mínimo libre <NumberFormat
                                        value={minPayday}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Deuda máxima <NumberFormat
                                        value={maxDebt}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Inversión Máxima <NumberFormat
                                        value={maxInvestment}
                                        displayType={'text'}
                                        decimalScale={2}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>)
}
export default InvestingPrinciple;
